import React, { useState, createContext } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});

    const logout = () => {
        localStorage.removeItem('puj_user');
        setUser({});
    };

    return <AuthContext.Provider value={{ user, logout, setUser }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
