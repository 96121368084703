import React from 'react';

// material-ui
import { makeStyles, styled } from '@material-ui/styles';
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Radio,
    Switch,
    FormGroup
} from '@material-ui/core';

// style constant
const useStyles = makeStyles((theme) => ({
    itemHeader: {
        background: '#F6F6F6',
        padding: '3px 20px'
    },
    avatar: {
        borderRadius: '50% !important'
    },
    textNotification: {
        width: '80%'
    },
    navContainer: {
        width: '100%',
        maxWidth: '380px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px'
        }
    },
    listAction: {
        top: '22px'
    },
    actionColor: {
        color: theme.palette.grey[500]
    },

    listItem: {
        padding: 0
    },
    sendIcon: {
        marginLeft: '8px',
        marginTop: '-3px'
    },
    listDivider: {
        marginTop: 0,
        marginBottom: 0
    },
    listChipError: {
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        height: '24px',
        padding: '0 6px',
        marginRight: '5px'
    },
    listChipWarning: {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
        height: '24px',
        padding: '0 6px'
    },
    listChipSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: '24px',
        padding: '0 6px'
    },
    listAvatarSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.success.main
    },
    listAvatarPrimary: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.primary.main
    },
    uploadCard: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light
    },
    paddingBottom: {
        paddingBottom: '16px'
    },
    itemAction: {
        cursor: 'pointer',
        padding: '16px',
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
        }
    },
    textBtn: {
        color: '#787373',
        fontWeight: 100,
        fontSize: '12px',
        '&:hover': {
            background: 'none'
        }
    },
    radioBtn: {
        '& .MuiFormControlLabel-root .MuiTypography-root': {
            fontSize: '13px'
        }
    }
}));
// CustomSwitch
const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    margin: '0px 10px',
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
    }
}));
// ===========================|| NOTIFICATION LIST ITEM ||=========================== //

const NotificationList = () => {
    const classes = useStyles();

    return (
        <List className={classes.navContainer}>
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemText primary={<Typography variant="subtitle1">Do not disturb</Typography>} />
                    <ListItemSecondaryAction className={classes.listAction}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormGroup row className={classes.radioBtn}>
                                        <FormControlLabel
                                            label="Off"
                                            labelPlacement="end"
                                            sx={{ color: '#212121', fontSize: '13px' }}
                                            control={<CustomSwitch />}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column">
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">Block all notifications from this app</Typography>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemText primary={<Typography variant="subtitle1">Comments</Typography>} />
                </ListItem>
                <Grid container direction="column">
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">When somebody replies on your comments or mention you in theirs,</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControl>
                        <RadioGroup column aria-label="comments" name="row-radio-buttons-group" className={classes.radioBtn}>
                            <FormControlLabel value="donot_notify" control={<Radio />} label="Do not notify me" />
                            <FormControlLabel value="mentions" control={<Radio />} label="Mentions only" />
                            <FormControlLabel value="all_comments" control={<Radio />} label="All comments" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemText primary={<Typography variant="subtitle1">Reminders</Typography>} />
                </ListItem>
                <Grid container direction="column">
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">In case if you miss any job applications</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControl>
                        <RadioGroup column aria-label="reminder" name="row-radio-buttons-group" className={classes.radioBtn}>
                            <FormControlLabel value="not_notify" control={<Radio />} label="Do not notify me" />
                            <FormControlLabel value="imp_reminders" control={<Radio />} label="Important All reminders only" />
                            <FormControlLabel value="all_reminders" control={<Radio />} label="All reminders" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </div>
        </List>
    );
};

export default NotificationList;
