/**
 * axios setup to use mock service
 */

import axios from 'axios';

export const BASE_URL = 'https://app.resupix.com/api';
// export const BASE_URL = 'https://dv-api.resupic.com.au/api';
// export const BASE_URL = 'http://127.0.0.1:8000/api';

const axiosServices = axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL
});

export default axiosServices;
