import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const BlankDashboard = Loadable(lazy(() => import('views/dashboardBlank')));
const Jobs = Loadable(lazy(() => import('views/Jobs')));
const CreateJob = Loadable(lazy(() => import('views/createJob')));
const EditJob = Loadable(lazy(() => import('views/EditJob')));
const DetailsJob = Loadable(lazy(() => import('views/DetailsJob')));
const Settings = Loadable(lazy(() => import('views/Settings')));
const ApplicantDetails = Loadable(lazy(() => import('views/DetailsJob/ApplicantDetails')));
const AppChat = Loadable(lazy(() => import('views/chat')));
const Browse = Loadable(lazy(() => import('views/Browse')));
const MyLists = Loadable(lazy(() => import('views/MyLists')));
const Profile = Loadable(lazy(() => import('views/Profile')));
const Payment = Loadable(lazy(() => import('views/Payment')));
const CandidateDetails = Loadable(lazy(() => import('views/Browse/CandidateDetails')));
const PlansAndPricing = Loadable(lazy(() => import('views/PlansAndPricing')));
const AddCategoryItem = Loadable(lazy(() => import('views/Profile/Category/AddCategoryItem')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/blank-dashboard',
            element: <BlankDashboard />
        },
        {
            path: '/jobs',
            element: <Jobs />
        },
        {
            path: '/jobs/create',
            element: <CreateJob />
        },
        {
            path: '/jobs/create/:id',
            element: <CreateJob />
        },
        {
            path: '/jobs/edit',
            element: <EditJob />
        },
        {
            path: '/jobs/details/:id',
            element: <DetailsJob />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/Applicant/Details/:id/:jobID',
            element: <ApplicantDetails />
        },
        {
            path: '/app/chat',
            element: <AppChat />
        },
        {
            path: '/app/chat/:userId',
            element: <AppChat />
        },
        {
            path: '/app/browse',
            element: <Browse />
        },
        {
            path: '/app/my-lists',
            element: <MyLists />
        },
        {
            path: '/app/profile',
            element: <Profile />
        },
        {
            path: '/app/profile/category/add/:id',
            element: <AddCategoryItem />
        },
        {
            path: '/app/payment',
            element: <Payment />
        },
        {
            path: '/app/candidate-details/:id',
            element: <CandidateDetails />
        },
        {
            path: '/plans-pricing',
            element: <PlansAndPricing />
        }
    ]
};

export default MainRoutes;
