import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, List, ListItem, ListItemText, Typography, Button } from '@material-ui/core';

// assets
import imgMain from 'assets/images/card/casual.png';

// style constant
const useStyles = makeStyles(() => ({
    card: {
        background: 'radial-gradient(130.31% 147.57% at 100% -25%, #E8E74B 0%, #33BC84 93.17%)',
        marginBottom: '22px',
        overflow: 'hidden',
        // position: 'relative',
        padding: '20px'
    },
    listPrimary: {
        color: 'white',
        marginTop: '90px'
    },
    listsecondary: {
        color: 'white',
        fontSize: '13px',
        lineHeight: '1.3',
        marginTop: '10px'
    },
    cardImage: {
        position: 'absolute',
        top: '-35px'
    }
}));

// ===========================|| SIDEBAR MENU Card ||=========================== //

const MenuCard = () => {
    const classes = useStyles();

    return (
        <div style={{ position: 'relative', marginTop: '40px' }}>
            <Card className={classes.card}>
                <CardContent sx={{ p: 2 }}>
                    <img alt="Auth method" src={imgMain} className={classes.cardImage} />
                    <List sx={{ p: 0, m: 0 }}>
                        <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                            <ListItemText
                                sx={{ mt: 0 }}
                                primary={
                                    <Typography variant="h3" className={classes.listPrimary}>
                                        Upscale
                                    </Typography>
                                }
                                secondary={<Typography className={classes.listsecondary}>To our premium plans & engage more!</Typography>}
                            />
                        </ListItem>
                    </List>
                    <Button
                        variant="contained"
                        sx={{
                            px: '20px',
                            height: '45px',
                            borderRadius: 2,
                            position: 'absolute',
                            bottom: '-20px',
                            background: 'black',
                            boxShadow: '0px 18px 27px -1px #cfcdcd'
                        }}
                        className={classes.upgradBtn}
                        href="/plans-pricing"
                    >
                        Upgrade Now
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
};
export default MenuCard;
