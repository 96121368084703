import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, Box } from '@material-ui/core';

// assets
import User1 from 'assets/images/users/user-round.svg';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// style constant
const useStyles = makeStyles((theme) => ({
    itemHeader: {
        background: '#F6F6F6',
        padding: '3px 20px'
    },
    avatar: {
        borderRadius: '50% !important'
    },
    textNotification: {
        width: '220px'
    },
    navContainer: {
        width: '100%',
        maxWidth: '380px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px'
        }
    },
    listAction: {
        top: '22px'
    },
    actionColor: {
        color: theme.palette.grey[500]
    },

    listItem: {
        padding: 0
    },
    sendIcon: {
        marginLeft: '8px',
        marginTop: '-3px'
    },
    listDivider: {
        marginTop: 0,
        marginBottom: 0
    },
    listChipError: {
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        height: '24px',
        padding: '0 6px',
        marginRight: '5px'
    },
    listChipWarning: {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
        height: '24px',
        padding: '0 6px'
    },
    listChipSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: '24px',
        padding: '0 6px'
    },
    listAvatarSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.success.main
    },
    listAvatarPrimary: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.primary.main
    },
    listContainer: {
        paddingLeft: '56px'
    },
    uploadCard: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light
    },
    paddingBottom: {
        paddingBottom: '16px'
    },
    itemAction: {
        cursor: 'pointer',
        padding: '16px',
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
        }
    },
    textBtn: {
        color: '#787373',
        fontWeight: 100,
        fontSize: '12px',
        '&:hover': {
            background: 'none'
        }
    },
    textActive: {
        width: '10px',
        height: '10px',
        verticalAlign: 'sub',
        color: theme.palette.primary.main,
        position: 'absolute',
        left: '-13px',
        bottom: ' 13px'
    }
}));

// ===========================|| NOTIFICATION LIST ITEM ||=========================== //

const NotificationList = () => {
    const classes = useStyles();

    return (
        <List className={classes.navContainer}>
            <div className={classes.itemHeader}>
                <Typography variant="subtitle2">Today</Typography>
            </div>
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <FiberManualRecordIcon className={classes.textActive} />
                        <Avatar alt="John Doe" src={User1} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ display: 'flex !important' }}
                        primary={
                            <Box sx={{ display: 'flex !important' }}>
                                <Typography variant="subtitle2" className={classes.textNotification}>
                                    Mandira Bedi started following you
                                </Typography>
                                <Grid container justifyContent="flex-end" sx={{ width: '67px' }}>
                                    <Typography
                                        justifyContent="flex-end"
                                        variant="caption1"
                                        display="block"
                                        gutterBottom
                                        className={classes.actionColor}
                                    >
                                        1h ago
                                    </Typography>
                                </Grid>
                            </Box>
                        }
                    />
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation>
                                    Follow Back
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ display: 'flex !important' }}
                        primary={
                            <Box sx={{ display: 'flex !important' }}>
                                <Typography variant="subtitle2" className={classes.textNotification}>
                                    Magellan Davis has applied to the job, A lengthy job name, Location.
                                </Typography>
                                <Grid container justifyContent="flex-end" sx={{ width: '67px' }}>
                                    <Typography
                                        justifyContent="flex-end"
                                        variant="caption1"
                                        display="block"
                                        gutterBottom
                                        className={classes.actionColor}
                                    >
                                        10:16 AM
                                    </Typography>
                                </Grid>
                            </Box>
                        }
                    />
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation>
                                    View Profile
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemHeader}>
                <Typography variant="subtitle2">Yesterday</Typography>
            </div>
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ display: 'flex !important' }}
                        primary={
                            <Box sx={{ display: 'flex !important' }}>
                                <Typography variant="subtitle2" className={classes.textNotification}>
                                    Abhishek Bachchan sent you a message
                                </Typography>
                                <Grid container justifyContent="flex-end" sx={{ width: '67px' }}>
                                    <Typography
                                        justifyContent="flex-end"
                                        variant="caption1"
                                        display="block"
                                        gutterBottom
                                        className={classes.actionColor}
                                    >
                                        06:32 pM
                                    </Typography>
                                </Grid>
                            </Box>
                        }
                    />
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button
                                    variant="text"
                                    size="small"
                                    className={classes.textBtn}
                                    startIcon={<ShortcutOutlinedIcon sx={{ transform: 'scaleX(-1)' }} />}
                                >
                                    Reply
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="text" size="small" className={classes.textBtn} startIcon={<DoneOutlinedIcon />}>
                                    Mark As Read
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ display: 'flex !important' }}
                        primary={
                            <Box sx={{ display: 'flex !important' }}>
                                <Typography variant="subtitle2" className={classes.textNotification}>
                                    Mandira Bedi started following you
                                </Typography>
                                <Grid container justifyContent="flex-end" sx={{ width: '67px' }}>
                                    <Typography
                                        justifyContent="flex-end"
                                        variant="caption1"
                                        display="block"
                                        gutterBottom
                                        className={classes.actionColor}
                                    >
                                        02:15 PM
                                    </Typography>
                                </Grid>
                            </Box>
                        }
                    />
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation>
                                    Follow Back
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemHeader}>
                <Typography variant="subtitle2">Mon 24 Mar 2022</Typography>
            </div>
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ display: 'flex !important' }}
                        primary={
                            <Box sx={{ display: 'flex !important' }}>
                                <Typography variant="subtitle2" className={classes.textNotification}>
                                    Magellan Davis has applied to the job, A lengthy job name, Location.
                                </Typography>
                                <Grid container justifyContent="flex-end" sx={{ width: '67px' }}>
                                    <Typography
                                        justifyContent="flex-end"
                                        variant="caption1"
                                        display="block"
                                        gutterBottom
                                        className={classes.actionColor}
                                    >
                                        10:16 AM
                                    </Typography>
                                </Grid>
                            </Box>
                        }
                    />
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation>
                                    View Profile
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
        </List>
    );
};

export default NotificationList;
