import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import useAuth from 'hooks/useAuth';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { SET_CATEGORIES, SET_META_DATA, SET_USER_CATEGORIES } from 'store/actions';

// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    const { user } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const getMetadata = async () => {
        try {
            const {
                data: { data }
            } = await axiosPrivate.get('/v1/meta-data?lang_code=en');
            dispatch({ type: SET_META_DATA, payload: data });
            // dispatch({ type: SET_CATEGORIES, payload: data.tag_categories_company });
        } catch (error) {
            console.log('🚀 ~ file: App.js ~ getMetadata ~ error', error);
        }
    };

    const getUserCategories = async () => {
        try {
            const {
                data: { data }
            } = await axiosPrivate.get('/v1.8.4/all-list-media-categories', {
                params: {
                    lang_code: 'en',
                    type: 'company',
                    user_id: user.id
                }
            });
            dispatch({ type: SET_USER_CATEGORIES, payload: data });

            const dataFormatted = data.map((item) => ({ ...item, category_id: item.id }));
            dispatch({ type: SET_CATEGORIES, payload: dataFormatted });
        } catch (error) {
            console.log('🚀 ~ file: App.js ~ getUserCategories ~ error', error);
        }
    };

    useEffect(() => {
        if (user?.access_token) {
            getMetadata();
            getUserCategories();
        }
    }, [user?.access_token]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        <Routes />
                        <Snackbar />
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
