import { SET_META_DATA } from './actions';

const metadataReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_META_DATA: {
            const data = action.payload;
            return {
                ...state,
                data
            };
        }
        default:
            return state;
    }
};

export default metadataReducer;
