/**
 * MUI Components whose styles are override as per theme
 * @param {JsonObject} theme Plain Json Object
 */
export default function componentStyleOverrides(theme) {
    const bgColor = theme.customization.navType === 'dark' ? theme.colors.darkBackground : theme.colors.grey50;

    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    borderRadius: '4px',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: theme.colors.darkSecondaryMain
                    }
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme.customization.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '16px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderRadius: '0px',
                    borderLeft: '3px solid white',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: 'transparent',
                        borderLeft: '3px solid #33BC84',
                        borderRadius: '0px',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderLeft: '3px solid #33BC84'
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme.customization.outlinedFilled ? bgColor : '#FAFAFA',
                    borderRadius: `${theme.customization.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 28 : theme.colors.grey100
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: '30.5px 14px 11.5px !important'
                    }
                },
                input: {
                    fontWeight: 500,
                    background: theme.customization.outlinedFilled ? bgColor : '#FAFAFA',
                    fontSize: '14px',
                    // padding: '15.5px 14px',
                    // padding: '30.5px 2px 11.5px !important',
                    borderRadius: `${theme.customization.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme.customization.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 50 : theme.colors.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme.customization.navType === 'dark' ? theme.colors.primaryMain : theme.colors.primaryLight
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 20 : theme.colors.primaryMain,
                        borderRadius: 6,
                        color: theme.colors.grey50,
                        '.MuiChip-deleteIcon': {
                            color: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 80 : theme.colors.grey50
                        }
                    }
                },
                popper: {
                    borderRadius: `${theme.customization.borderRadius}px`,
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
                    zIndex: '1000'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: theme.customization.navType === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.customization.navType === 'dark' ? theme.colors.darkLevel1 : theme.colors.primaryDark,
                    background: theme.customization.navType === 'dark' ? theme.darkTextPrimary : theme.colors.primary200,
                    borderRadius: '8px'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                },
                colorError: {
                    backgroundColor: '#C1C1C1'
                },
                colorSecondary: {
                    backgroundColor: '#2196F3'
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme.customization.navType === 'dark' ? theme.colors.darkPaper : theme.colors.primaryLight,
                    '& .MuiTabs-flexContainer': {
                        borderColor: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 20 : theme.colors.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme.customization.navType === 'dark' ? theme.colors.darkTextSecondary : theme.colors.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors.secondaryMain
                    },
                    '& .Mui-selected': {
                        color: theme.colors.primaryDark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 20 : theme.colors.grey200,
                    '& .MuiTab-root': {
                        color: '#C1C1C1',
                        textTransform: 'capitalize',
                        fontWeight: 100
                    },
                    '& .MuiTab-root.Mui-selected': {
                        color: '#181818',
                        fontWeight: 500
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 15 : theme.colors.grey200,
                    '&.MuiTableCell-head': {
                        fontSize: '0.875rem',
                        color: theme.heading,
                        fontWeight: 500
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.customization.navType === 'dark' ? theme.colors.darkLevel1 : theme.paper,
                    background: theme.customization.navType === 'dark' ? theme.colors.grey50 : theme.colors.grey700
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem'
                }
            }
        },
        MuiInput: {
            root: {
                border: '1px solid rgba(255,255,255,0.32)',
                borderRadius: 8,
                overflow: 'hidden',
                alignItems: 'center',
                transition: 'border 0.3s ease'
            },
            underline: {
                '&:after': {
                    height: 'calc(100% + 1px)',
                    borderRadius: 8,
                    bottom: -1
                },
                '&:before': {
                    display: 'none'
                }
            },
            input: {
                padding: 10,
                fontSize: 14
            },
            multiline: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 24
            }
        },
        MuiFormControl: {
            root: {
                '& label + div': {
                    alignItems: 'flex-end',
                    '&[role="radiogroup"]': {
                        alignItems: 'flex-start'
                    },
                    paddingBottom: 4,
                    '& input, > div, > select': {
                        padding: '24px 8px 0'
                    }
                }
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 14
            }
        },
        MuiInputLabel: {
            formControl: {
                top: 12,
                left: 10,
                transform: 'translate(0, 22px) scale(1)'
            },
            shrink: {
                transform: 'translate(0, 13px) scale(0.7)',
                zIndex: 1
            },
            filled: {
                transform: 'translate(2px, 6px) scale(1)',
                '&$shrink': {
                    transform: 'translate(0px, -6px) scale(0.75)'
                }
            },
            outlined: {
                transform: 'translate(2px, 6px) scale(1)',
                '&$shrink': {
                    transform: 'translate(4px, -16px) scale(0.75)'
                }
            }
        }
        // MuiSwitch: {
        //     width: 28,
        //     height: 16,
        //     padding: 0,
        //     display: 'flex',
        //     '&:active': {
        //         '& .MuiSwitch-thumb': {
        //             width: 15
        //         },
        //         '& .MuiSwitch-switchBase.Mui-checked': {
        //             transform: 'translateX(9px)'
        //         }
        //     },
        //     '& .MuiSwitch-switchBase': {
        //         padding: 2,
        //         '&.Mui-checked': {
        //             transform: 'translateX(12px)',
        //             color: '#fff',
        //             '& + .MuiSwitch-track': {
        //                 opacity: 1,
        //                 backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
        //             }
        //         }
        //     },
        //     '& .MuiSwitch-thumb': {
        //         boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        //         width: 12,
        //         height: 12,
        //         borderRadius: 6,
        //         transition: theme.transitions.create(['width'], {
        //             duration: 200
        //         })
        //     },
        //     '& .MuiSwitch-track': {
        //         borderRadius: 16 / 2,
        //         opacity: 1,
        //         backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        //         boxSizing: 'border-box'
        //     }
        // }
    };
}
