import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconSmartHome,
    IconBriefcase,
    IconMessageDots,
    IconSettings,
    IconUsers,
    IconUser,
    IconListCheck,
    IconCreditCard
} from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconSmartHome,
    IconBriefcase,
    IconMessageDots,
    IconSettings,
    IconUsers,
    IconUser,
    IconListCheck,
    IconCreditCard
};

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //
const user = JSON.parse(localStorage.getItem('puj_user'));

const children = [
    {
        id: 'dashboard',
        title: <FormattedMessage id="Dashboard" />,
        type: 'item',
        url: '/dashboard',
        icon: icons.IconSmartHome,
        breadcrumbs: false
    },
    {
        id: 'jobs',
        title: <FormattedMessage id="Jobs" />,
        type: 'item',
        url: '/jobs',
        icon: icons.IconBriefcase,
        breadcrumbs: false
    },
    {
        id: 'browse',
        title: <FormattedMessage id="Browse" />,
        type: 'item',
        url: '/app/browse',
        icon: icons.IconUsers,
        breadcrumbs: false
    },
    {
        id: 'my-list',
        title: <FormattedMessage id="My Lists" />,
        type: 'item',
        url: '/app/my-lists',
        icon: icons.IconListCheck,
        breadcrumbs: false
    },
    {
        id: 'Message',
        title: <FormattedMessage id="Message" />,
        type: 'item',
        url: '/app/chat',
        icon: icons.IconMessageDots,
        breadcrumbs: false
    },
    {
        id: 'plans-pricing',
        title: <FormattedMessage id="Plans & Pricing" />,
        type: 'item',
        url: '/plans-pricing',
        icon: icons.IconCreditCard,
        breadcrumbs: false
    },
    {
        id: 'profile',
        title: <FormattedMessage id="Profile" />,
        type: 'item',
        url: '/app/profile',
        icon: icons.IconUser,
        breadcrumbs: false
    }
    // {
    //     id: 'settings',
    //     title: <FormattedMessage id="Settings" />,
    //     type: 'item',
    //     url: '/settings',
    //     icon: icons.IconSettings,
    //     breadcrumbs: false
    // }
    // {
    //     id: 'sample-page',
    //     title: <FormattedMessage id="sample-page" />,
    //     type: 'item',
    //     url: '/sample-page',
    //     icon: icons.IconBrandChrome,
    //     breadcrumbs: false
    // }
];

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: user?.subscribed === true ? [...children] : [...children.filter((x) => x.id !== 'my-list')]
};

export default other;
