import React, { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/login')));

// const AuthRegister2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/Register2')));
// const AuthForgotPassword2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/ForgotPassword2')));
// ===========================|| AUTH ROUTING ||=========================== //

const LoginRoutes = {
    path: 'login',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: (
                <NavMotion>
                    <GuestGuard>
                        <AuthLogin />
                    </GuestGuard>
                </NavMotion>
            )
        }
        // {
        //     path: '/pages/register/register2',
        //     element: <AuthRegister2 />
        // },
        // {
        //     path: '/pages/forgot-password/forgot-password2',
        //     element: <AuthForgotPassword2 />
        // }
    ]
};

export default LoginRoutes;
