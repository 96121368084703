import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

// login option 2 routing

const AuthRegister2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/Register2')));
const AuthForgotPassword2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/ForgotPassword2')));
const AuthCheckMail2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/CheckMail2')));
const AuthConfirmMail = Loadable(lazy(() => import('views/pages/authentication/authentication2/ConfirmMail')));
const AuthResetPassword2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/ResetPassword2')));
const AuthCodeVerification2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/CodeVerification2')));
const AuthCreatePassword = Loadable(lazy(() => import('views/pages/authentication/authentication2/CreatePassword')));
const AuthResetPasswordMail = Loadable(lazy(() => import('views/pages/authentication/authentication2/ResetPasswordMail')));
// ===========================|| AUTHENTICATION ROUTING ||=========================== //
const PagesLanding = Loadable(lazy(() => import('views/pages/landing/index')));
const AddService = Loadable(lazy(() => import('views/pages/landing/AddService')));
const AddProduct = Loadable(lazy(() => import('views/pages/landing/AddProduct')));
const InviteUser = Loadable(lazy(() => import('views/Settings/InviteUser')));
const AddCategoryItem = Loadable(lazy(() => import('views/pages/landing/AddCategoryItem')));
// const LandingPage = Loadable(lazy(() => import('views/pages/LandingPage/index')));
const ContactUs = Loadable(lazy(() => import('views/pages/LandingPage/ContactUs')));
const AboutUs = Loadable(lazy(() => import('views/pages/LandingPage/AboutUs')));
const PrivacyAndPolicy = Loadable(lazy(() => import('views/pages/LandingPage/PrivacyAndPolicy')));
const TermsAndConditions = Loadable(lazy(() => import('views/pages/LandingPage/TermsAndConditions')));
const CompanyExternalShareView = Loadable(lazy(() => import('views/CompanyExternalShareView')));

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/register/register2',
            element: <AuthRegister2 />
        },
        {
            path: '/pages/forgot-password/forgot-password2',
            element: <AuthForgotPassword2 />
        },
        {
            path: '/pages/check-mail/check-mail2',
            element: <AuthCheckMail2 />
        },
        {
            path: '/pages/confirm-mail/confirm-mail',
            element: <AuthConfirmMail />
        },
        {
            path: '/pages/reset-password/reset-password2',
            element: <AuthResetPassword2 />
        },
        {
            path: '/pages/code-verification/code-verification2',
            element: <AuthCodeVerification2 />
        },
        {
            path: '/pages/create-password/create-password/:Id',
            element: <AuthCreatePassword />
        },
        {
            path: '/pages/reset-password-mail/reset-password',
            element: <AuthResetPasswordMail />
        },
        {
            path: '/pages/landing',
            element: <PagesLanding />
        },
        {
            path: '/service/add-new',
            element: <AddService />
        },
        {
            path: '/product/add-new',
            element: <AddProduct />
        },

        {
            path: '/settings/invite-user',
            element: <InviteUser />
        },
        {
            path: '/settings/invite-user',
            element: <InviteUser />
        },
        {
            path: '/category/add',
            element: <AddCategoryItem />
        },
        // {
        //     path: '/LandingPage',
        //     element: <LandingPage />
        // },
        {
            path: '/ContactUs',
            element: <ContactUs />
        },
        {
            path: '/AboutUs',
            element: <AboutUs />
        },
        {
            path: '/PrivacyAndPolicy',
            element: <PrivacyAndPolicy />
        },
        {
            path: '/TermsAndConditions',
            element: <TermsAndConditions />
        },
        {
            path: '/company-external-share-view',
            element: <CompanyExternalShareView />
        }
    ]
};

export default AuthenticationRoutes;
