import { useEffect } from 'react';
import { axiosPrivate } from '../utils/axios';
import useAuth from './useAuth';

const useAxiosPrivate = () => {
    const { user } = useAuth();

    useEffect(() => {
        const requestInterceptor = axiosPrivate.interceptors.request.use(
            (config) => {
                if (!config.headers.Authorization) {
                    config.headers.Authorization = `Bearer ${user?.access_token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseInterceptor = axiosPrivate.interceptors.response.use(
            (response) => response,
            async (error) => {
                console.error('🚀 ~ file: useAxiosPrivate.js ~ useEffect ~ error', error);
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestInterceptor);
            axiosPrivate.interceptors.response.eject(responseInterceptor);
        };
    }, [user?.access_token]);

    return axiosPrivate;
};

export default useAxiosPrivate;
