import AuthContext from 'contexts/AuthContext';
import { useContext, useDebugValue, useEffect } from 'react';

// auth provider

// ===========================|| AUTH HOOKS ||=========================== //

const useAuth = () => {
    const { user, setUser } = useContext(AuthContext);

    useDebugValue(user, (user) => `User: ${user}`);

    useEffect(() => {
        try {
            const user = localStorage.getItem('puj_user');
            if (user) {
                setUser?.(JSON.parse(user));
            }
        } catch (error) {
            console.error('🚀 ~ file: useAuth.js ~ useEffect ~ error', error);
        }
    }, []);

    return useContext(AuthContext);
};

export default useAuth;
