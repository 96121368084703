import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import { useRoutes } from 'react-router-dom';

// project imports
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

const LandingPage = Loadable(lazy(() => import('views/pages/LandingPage/index')));

// ===========================|| ROUTING RENDER ||=========================== //

const user = localStorage.getItem('puj_user');

export default function ThemeRoutes() {
    return useRoutes([{ path: '/', element: <LandingPage /> }, LoginRoutes, MainRoutes, AuthenticationRoutes]);
}
