import {
    ADD_CATEGORY_ITEM,
    COMPLETE_CATEGORY_ITEM,
    DELETE_CATEGORY_ITEM,
    SET_CATEGORIES,
    UPDATE_CATEGORIES,
    UPDATE_CATEGORY_ITEM,
    UPDATE_STEP,
    UPDATE_STEP_PROFILE,
    ADD_CATEGORY_ITEM_PROFILE,
    DELETE_CATEGORY_ITEM_PROFILE,
    SET_USER_CATEGORIES,
    UPDATE_USER_CATEGORIES
} from './actions';

const initialState = {
    activeStep: 0,
    activeCategoryType: 'PROFILE',
    categories: [],
    categoriesData: {},
    userCategories: []
};

const companyProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: [
                    {
                        type: 'PROFILE',
                        category_name: 'Company Profile'
                    },
                    ...action.payload.map((categories) => ({ ...categories, type: 'CATEGORY' })),
                    {
                        type: 'NEW_CATEGORY',
                        category_name: '+ New Category'
                    }
                ]
            };
        case UPDATE_CATEGORIES:
            return {
                ...state,
                categories: [...state.categories, ...action.payload]
            };
        case UPDATE_STEP: {
            const activeStep = state.activeStep + action.payload.step;
            return {
                ...state,
                activeStep,
                activeCategoryType: state.categories[activeStep]?.type
            };
        }
        case UPDATE_STEP_PROFILE: {
            const activeStep = action.payload.step;
            return {
                ...state,
                activeStep,
                activeCategoryType: state.categories[activeStep]?.type
            };
        }
        case ADD_CATEGORY_ITEM: {
            return {
                ...state,
                categoriesData: {
                    ...state.categoriesData,
                    [state.activeStep]: {
                        rows: [
                            ...(state.categoriesData[state.activeStep]?.rows ?? []),
                            {
                                ...action.payload
                            }
                        ]
                    }
                }
            };
        }
        case UPDATE_CATEGORY_ITEM: {
            return {
                ...state,
                categoriesData: {
                    ...state.categoriesData,
                    [state.activeStep]: {
                        rows: state.categoriesData[state.activeStep].rows.map((row) => {
                            if (row.media_id === action.payload.media_id) {
                                return action.payload;
                            }
                            return row;
                        })
                    }
                }
            };
        }
        case DELETE_CATEGORY_ITEM: {
            return {
                ...state,
                categoriesData: {
                    ...state.categoriesData,
                    [state.activeStep]: {
                        rows: state.categoriesData[state.activeStep].rows.filter((row) => row.media_id !== action.payload.media_id)
                    }
                }
            };
        }
        case COMPLETE_CATEGORY_ITEM: {
            return {
                ...state,
                categoriesData: {
                    ...state.categoriesData,
                    [state.activeStep]: {
                        isComplete: action.payload.isComplete
                    }
                }
            };
        }
        case ADD_CATEGORY_ITEM_PROFILE: {
            return {
                ...state,
                categoriesData: action.payload
            };
        }
        case DELETE_CATEGORY_ITEM_PROFILE: {
            const activeStep = state.categoriesData[state.activeStep].rows.filter((row) => row.media_id !== action.payload.media_id);
            return {
                ...state,
                categoriesData: {
                    [state.activeStep]: {
                        rows: activeStep
                    }
                }
            };
        }
        case SET_USER_CATEGORIES: {
            return {
                ...state,
                userCategories: [
                    {
                        type: 'PROFILE',
                        category_name: 'Company Profile'
                    },
                    ...action.payload.map((categories) => ({ ...categories, type: 'CATEGORY' })),
                    {
                        type: 'NEW_CATEGORY',
                        category_name: '+ New Category'
                    }
                ]
            };
        }
        default:
            return state;
    }
};

export default companyProfileReducer;
